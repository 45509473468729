<script lang="ts">
  import { inertia } from "@inertiajs/svelte";
  import {MetaTags} from 'svelte-meta-tags'
  let { pkg } = $props();
</script>

<p><a use:inertia href={`/categories/${pkg.category.slug}`}>{pkg.category?.name}</a></p>

<MetaTags title={pkg.title} />
<h1>
  <span>{pkg.title}</span>
  {#if pkg.favicon_url}
    <img src={pkg.favicon_url} class="favicon inline" alt="favicon" />
  {/if}
</h1>

<nav>
  <ul>
    {#if pkg.homepage_url}
      <li>
        <i class="lucide-icon home"></i> <a href={pkg.homepage_url}>Homepage</a>
      </li>
    {/if}
    {#if pkg.repository_url}
      <li>
        <i class="lucide-icon github"></i> <a href={pkg.repository_url_without_git}>Repository</a>
      </li>
    {/if}
    {#if pkg.bugs_url}
      <li>
        <i class="lucide-icon bug"></i> <a href={pkg.bugs_url}>Bugs</a>
      </li>
    {/if}
  </ul>
</nav>

<p>{pkg.description}</p>

{#if pkg.keywords?.length}
  <h4>Keywords</h4>
  <ul class="inline-list">
    {#each pkg.keywords as keyword}
      <li>{keyword}</li>
    {/each}
  </ul>
{/if}
