<script lang="ts">
  import { inertia, router } from '@inertiajs/svelte'
  import {MetaTags} from 'svelte-meta-tags'

  let {category} = $props();
</script>
<MetaTags title={category.name} />


<h1>{category.name}</h1>
{#each category.packages as pkg}
  <article id={pkg.id} onclick={() => router.visit(`/packages/${pkg.slug}`)} class="cursor-pointer">
    <h2>
      <a use:inertia href="/packages/{pkg.slug}">{pkg.ident}</a>
      {#if pkg.favicon_url}
        <img src={pkg.favicon_url} class="favicon" alt="favicon">
      {/if}
    </h2>
  
    <footer>
      {pkg.description}
    </footer>
  </article>
{/each}