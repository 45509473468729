<script>
    import { Link } from '@inertiajs/svelte'
    import { useForm } from '@inertiajs/svelte'
    import {MetaTags} from 'svelte-meta-tags'

    export let pkg
    export let categories
  
    const form = useForm({
      ident: pkg.ident || '',
      category_id: pkg.category_id || '',
    })

    const handleSubmit = (e) => {
      $form.transform((data) => ({ package: data }))
      $form.post('/packages')
    }
  </script>
  
<MetaTags title="Add Package" />

  <h1>Add Package</h1>
  
  <form on:submit|preventDefault={handleSubmit}>
    <div>
      <label for="ident">Ident</label>
      <input
        type="text"
        name="ident"
        id="ident"
        bind:value={$form.ident}
      />
      {#if $form.errors.ident}
        <div class="error">{$form.errors.ident.join(', ')}</div>
      {/if}
    </div>
    <div>
      <label for="category_id">Category</label>
      <select name="category_id" id="category_id" bind:value={$form.category_id} >
        {#each categories as category}
          <option value={category.id}>{category.name}</option>
        {/each}
      </select>
    </div>
    {#if $form.errors.category}
      <div class="error">{$form.errors.category.join(', ')}</div>
    {/if}
    <div>
      <button type="submit" disabled={$form.processing}>Add Package</button>
    </div>
  </form>
  
  <style>
    label {
      display: block;
    }
    .error {
      color: red;
    }
  </style>
    
  <br />
  
  <div>
    <Link href="/things">Back to things</Link>
  </div>
  