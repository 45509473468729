<script>
    import { useForm } from '@inertiajs/svelte'
    import { createEventDispatcher } from 'svelte'
  
    const dispatch = createEventDispatcher()
  
    export let pkg
    export let categories
    export let submitText
  

    $: console.log(form, form?.errors)
    
  </script>
  
  