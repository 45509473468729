<script lang="ts">
import { inertia } from '@inertiajs/svelte'
import {MetaTags} from 'svelte-meta-tags'

let { categories } = $props()
</script>

<MetaTags title="Categories" />

<h1>Categories</h1>
<div id="categories">
    {#each categories as category}
      <h2>{category.name}</h2>
      <ul>
        {#each category.children as child}
          <li class="list-none"><a use:inertia href="/categories/{child.slug}">{child.name}</a></li>
        {/each}
      </ul>
    {/each}
  </div>
  