<script>
  import { page } from "@inertiajs/svelte";
  import { inertia } from "@inertiajs/svelte";
  import { LogOut } from "lucide-svelte";
</script>

<header class="container">
  <nav>
    <ul>
      <li><a use:inertia href="/">DEV Toolbox</a></li>
      <li><a use:inertia href="/categories">Categories</a></li>
      <li><a use:inertia href="/packages/new">Add Package</a></li>
    </ul>
    <ul>
      {#if $page.props.auth.user}
      <li>
        
        {$page.props.auth.user?.email}
      <li><a use:inertia={{href: '/users/sign_out', method: 'delete'}}><LogOut class="align-baseline"/></a></li>
      {:else}
        <li><a href="/users/sign_up">Sign Up</a></li>
      {/if}
    </ul>
  </nav>
</header>
<main class="container">
  <div>
    <content>
      {#if $page.props.flash.alert}
        <div class="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-4" role="alert">
          <p class="font-bold">{$page.props.flash.alert}</p>
        </div>
      {/if}
      {#if $page.props.flash.notice}
        <div
          class="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 mb-4"
          role="alert"
        >
          <p class="font-bold">{$page.props.flash.notice}</p>
        </div>
      {/if}
      <slot />
    </content>
  </div>
</main>


